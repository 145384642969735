import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";

const Watch = ({tg}) => {
    const { t } = useTranslation();

    return (
        <>
            <main>
                <section className="referral-section">
                    <div className="container">
                        <div className="main-top"><h1 className="main-top__title">{t('Live')}</h1></div>

                        <div className="referral-section__box">
                            <div className="main-top"><p>{t('Online broadcast of the mining process.')}</p></div>
                            <div class="iv-embed" style={{margin:"0 auto",padding:0,border:0,width:'100%'}}>
                                <div class="iv-v" style={{display:"block",margin:0,padding:"1px",border:0,background:"#000"}}>
                                    <iframe class="iv-i" style={{display:"block",margin:"0 auto",padding:0,border:0}} src="https://open.ivideon.com/embed/v3/?server=100-aPh673RTfQPiD4iACRBqW0&amp;camera=983040&amp;width=&amp;height=&amp;lang=ru"  height="360" frameborder="0" allow="autoplay; fullscreen; clipboard-write; picture-in-picture"></iframe>
                                    </div>
                                </div>
                                    
                                            <script src="https://open.ivideon.com/embed/v3/embedded.js"></script>

                        </div>
                    </div>
                </section>
            </main>

        </>
    );
};

export default Watch;
